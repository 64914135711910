/**
* @license
*
* Font Family: Spline Sans
* Designed by: Eben Sorkin, Mirko Velimirović
* URL: https://www.fontshare.com/fonts/spline-sans
* © 2024 Indian Type Foundry
*
* Spline Sans Light 
* Spline Sans Regular 
* Spline Sans Medium 
* Spline Sans SemiBold 
* Spline Sans Bold 
* Spline Sans Variable (Variable font)

*
*/
@font-face {
  font-family: 'Spline Sans';
  src: url('../fonts/SplineSans-Light.woff2') format('woff2'),
       url('../fonts/SplineSans-Light.woff') format('woff'),
       url('../fonts/SplineSans-Light.ttf') format('truetype');
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: 'Spline Sans';
  src: url('../fonts/SplineSans-Regular.woff2') format('woff2'),
       url('../fonts/SplineSans-Regular.woff') format('woff'),
       url('../fonts/SplineSans-Regular.ttf') format('truetype');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: 'Spline Sans';
  src: url('../fonts/SplineSans-Medium.woff2') format('woff2'),
       url('../fonts/SplineSans-Medium.woff') format('woff'),
       url('../fonts/SplineSans-Medium.ttf') format('truetype');
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: 'Spline Sans';
  src: url('../fonts/SplineSans-SemiBold.woff2') format('woff2'),
       url('../fonts/SplineSans-SemiBold.woff') format('woff'),
       url('../fonts/SplineSans-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: 'Spline Sans';
  src: url('../fonts/SplineSans-Bold.woff2') format('woff2'),
       url('../fonts/SplineSans-Bold.woff') format('woff'),
       url('../fonts/SplineSans-Bold.ttf') format('truetype');
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}
/**
* This is a variable font
* You can control variable axes as shown below:
* font-variation-settings: wght 400.0;
*
* available axes:
'wght' (range from 300.0 to 700.0
*/
@font-face {
  font-family: 'Spline Sans Variable';
  src: url('../fonts/SplineSans-Variable.woff2') format('woff2'),
       url('../fonts/SplineSans-Variable.woff') format('woff'),
       url('../fonts/SplineSans-Variable.ttf') format('truetype');
  font-weight: 300 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Spline Sans Variable';
  src: url('../fonts/PublicSans-VariableItalic.woff2') format('woff2'),
       url('../fonts/PublicSans-VariableItalic.woff') format('woff'),
       url('../fonts/PublicSans-VariableItalic.ttf') format('truetype');
  font-weight: 100 900;
  font-display: swap;
  font-style: italic; 
}