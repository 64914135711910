.dx-htmleditor-underlined {
    height: 100%;
    font-family: Satoshi Variable;
    font-size: 2.1rem;
    padding-top: 100px;
}

.dx-texteditor-input, .dx-placeholder {
    font-family: Satoshi Variable;
}

.dx-htmleditor-content h3 {
    font-size: 2.7rem;
}

.dx-htmleditor-content h4 {
    font-size: 2.4rem;
}


.dx-htmleditor-content h5 {
    font-size: 2.1rem;
}

.dx-htmleditor-content p {
    padding-bottom: 16px;
}

.dx-htmleditor-content ol {
    padding-bottom: 16px
}

/* .dx-htmleditor-content p, ol {
    font-family: Inter;
    font-size: 1.9rem;
    font-variation-settings: "wght" 400;
    line-height: 1.6;
    letter-spacing: -0.2px;
} */

.dx-htmleditor-content blockquote {
    margin-bottom: 16px;
    font-size: 1.8rem;
}

.bible-text {
    opacity: .7
}

.dx-htmleditor-content td p {
    padding: 0px;
    /* color: red !important */
}

.dx-htmleditor-content table {
    margin-bottom: 16px;
}