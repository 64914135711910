.HideToolbar {
    opacity: 0;
    transition: .2s ease;
}

.ShowToolbar {
    opacity: 1;
    transition: .2s ease;
}

.HideAppbar {
    transform: translateY(-100%);
    transition: 0s .2s !important;
}

.ShowAppbar {
    transform: translateY(0%);
    transition: 0s !important;
}