/* @import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap'); */

/* @import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap'); */
/* @import url("https://use.typekit.net/zbz1xhh.css"); */

/* @import url('https://fonts.cdnfonts.com/css/switzer'); */
/* @import url('https://fonts.googleapis.com/css2?family=Encode+Sans:wght@100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap'); */
/* @import url("https://rsms.me/inter/inter.css"); */

/* Titled image */
/* Maybe */
/* @import url('https://fonts.googleapis.com/css2?family=Old+Standard+TT:ital,wght@0,400;0,700;1,400&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Libre+Bodoni:ital,wght@0,400..700;1,400..700&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display:ital@0;1&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=PT+Serif:ital,wght@0,400;0,700;1,400;1,700&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Prata&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Forum&family=Gilda+Display&family=Noto+Naskh+Arabic:wght@400..700&family=Sorts+Mill+Goudy:ital@0;1&family=Vidaloka&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Gilda+Display&display=swap');

/* No */
/* @import url('https://fonts.googleapis.com/css2?family=Trocchi&display=swap'); */

/* Inria se ve muy bien como fuente gral */
@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display:ital@0;1&family=Inria+Serif:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');

/* CSS */
:root {
  /* font-family: Inter, sans-serif; */
  /* font-feature-settings: 'liga' 1, 'calt' 1; */
  /* fix for Chrome */
  --app-link-color: #7b7dffed;
  --app-link-decoration-color: #8c8dff69;
}
@supports (font-variation-settings: normal) {
  /* :root { font-family: InterVariable, sans-serif; } */
}

::-moz-selection { /* Code for Firefox */
  color: white;
  /* background: yellow; */
  background: #7072FF;
}

::selection {
  color: white;
  /* background: yellow; */
  background: #7072FF;
}

.testttt a:visited {
  color: white !important;
}

.SelVer {
  background-color: #ffff00;
}

.VariableW600 {
  font-variation-settings: "wght" 600;
}

.VariableW500 {
  font-variation-settings: "wght" 500;
  font-weight: 500;
}

.VW500 {
  font-variation-settings: "wght" 500;
  font-weight: 500;
}

.VW600 {
  font-variation-settings: "wght" 600;
  font-weight: 600;
}

.NegativeListUl {
  padding-left: 1.2em !important;
}

.TestHighlightNote:hover {
  background-color: var(--highlight-referenced);
}

.shy {
  content: '\u00AD';
}

.VW700 {
  font-variation-settings: "wght" 700;
  font-weight: 700;
}

.IMGTEST {
  width: 100%;
  height: 265px;
  object-fit: cover;
  border-radius: 0px;
  /* max-width: 286px;
  border-radius: 20px;
  margin-top: 23px; */

  /* max-width: 286px;
  border-radius: 272px;
  margin-top: 23px; */
  /* border: 5px solid #ff7800; */
}

a {
  /* color: #7072ff; */
  /* color: #8382FF; */
  /* color: #8c8dff; */
  color: var(--app-link-color);
  /* text-decoration-thickness: 3px; */
  text-decoration-thickness: 1px;
  /* text-decoration-color: #7072ff69; */
  /* text-decoration-color: #8382FF69; */
  text-decoration-color: var(--app-link-decoration-color);

  /* color: rgb(112, 114, 255);
  text-decoration-thickness: 7px;
  text-decoration-color: rgba(112, 114, 255, 0.18);
  text-underline-offset: -4px;
  text-decoration-skip-ink: none; */
}

ul {
  /* margin-bottom: 16px; */
  padding-bottom: 0px !important;
  /* margin-top: 16px; */
}

ol {
  margin-left: 4em;
  padding: 0;
  position: relative;
  counter-reset: ol-step-counter;
}

/* font-family: Spline Sans Variable;
  font-variation-settings: 'wght' 300;
  font-size: 1.9rem; */

ol li {
  /* list-style: decimal; */
  list-style: none;
  counter-increment: ol-step-counter;
  /* padding-bottom: 0px !important; */
}

.SatoshiFeatureSettings {
  font-feature-settings: 'ss03', 'ss02' !important;
  font-family: 'Satoshi' !important;
}

/* ol li::before {
  background: #0000000f;
  content: counter(ol-step-counter);
  flex-shrink: 0;
  justify-content: center;
  transform: translateY(-0.1em);
  font-weight: 500;
  font-variation-settings: "wght" 500;
  margin-left: -3em;
  height: 1.7em;
  width: 1.7em;
  font-size: 1em;
  border-radius: 50%;
  display: inline-flex;
  position: absolute;
  line-height: 1.7142857143;
} */

@media (max-width: 899px) {
  /* Styles for screens under 900px wide go here */
  ol li::before {
    /* margin-left: 0px; */
    margin-left: -2em;
  }
  ol li {
    padding-left: 2em;
  }
  ol {
    /* padding: 1.8em; */
  }
}

/* @font-face {
  font-family: "ProximaEco";
  src: url("./resources/ProximaVara.woff2") format("woff2");
} */

/* @font-face {
  font-family: "Bani Source Pro";
  src: url("./resources/BaniSansProD.woff2") format("woff2");
} */

/* @font-face {
  font-family: "Bani Serif Pro";
  src: url("./resources/BaniSerifD.woff2") format("woff2");
} */

/* @font-face {
  font-family: "G Text";
  font-weight: regular;
  src: url("./resources/GText-Regular.woff2") format("woff2");
} */

/* @font-face {
  font-family: "G Text";
  font-weight: bold;
  src: url("./resources/GText-Bold.woff2") format("woff2");
} */

/* @font-face {
  font-family: "G Display";
  font-weight: regular;
  src: url("./resources/GDisplay-Regular.woff2") format("woff2");
} */

/* @font-face {
  font-family: "G Display";
  font-weight: bold;
  src: url("./resources/GDisplay-Bold.woff2") format("woff2");
} */

/* @font-face {
  font-family: "Roboto Flex";
  src: url("./resources/RobotoFlex-VariableFont_GRAD,XTRA,YOPQ,YTAS,YTDE,YTFI,YTLC,YTUC,opsz,slnt,wdth,wght.woff2") format("woff2");
} */

.App {
  /* text-align: center; */
}

#video {
  position: fixed;
  z-index: -1;
  /* width: 130%; */
  left: 80%;
  filter: hue-rotate(221deg) invert(1);
  transform: scale(2.5) translateY(-10%) translateX(-8%);
  /* top: -50vh; */
}

#video_background {
  filter: blur(30px);
  min-height: 100vh;
  overflow: hidden;
}

#noise {
  min-width: 100vw;
  min-height: 100vh;
  position: fixed;
  background-image: url(/src/resources/grain_f.png);
  background-position: 0 0;
  background-size: 378px;
  /* opacity: .65; */
  /* z-index: 100000; */
  z-index: 1;
  left: 0;
  top: 0;
  /* filter: hue-rotate(45deg); */
  right: 0;
  bottom: 0;
  position: absolute;
}

/* -------------------------------------------------------------- */

:root {
  /* --uno: #282C34;
  --dos: #FF784F;
  --tres: #BBCBCB;
  --cuatro: #32965D;
  --cinco: #0B6E4F; */
  --uno: #fff;
  --dos: #FF784F;
  --tres: #000;
  --cuatro: #32965D;
  --cinco: #0B6E4F;
}
.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.App {
  /* text-align: center; */
  /* height: 100vh; */
  /* this worked fine. but it caused overflow in the harmony */
  /* width: 100vw; */
  width: 100%
}

/* .App circle {
  stroke: gray !important;
} */