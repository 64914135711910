:root {
    --padding-left: 3px;
    --padding-right: 3px;
    --margin-left: -3px;
    --margin-right: -3px;
    --border-radius: 6px;
}

.RDA_Advertencia u {
    text-decoration-thickness: 2px;
    text-decoration-style: double;
}

.RDA_Raiz u {
    text-decoration-thickness: 2px;
    text-decoration-style: double;
}

.RDA_NumVer {
    opacity: .4;
}

.RDA_Pecado {
    background: #F2EBD7;
    color: #F31904;
    border-radius: var(--padding-left);
    padding-left: var(--padding-right);
    margin-left: var(--margin-left);
    margin-right: var(--margin-right);
    padding-right: var(--border-radius);
}

.RDA_Advertencia {
    /* background: #F2EBD7;
    color: #F31904; */
    background: #ECDDFE;
    color: #FF5500;
border-radius: var(--padding-left);
    padding-left: var(--padding-right);
    margin-left: var(--margin-left);
    margin-right: var(--margin-right);
    padding-right: var(--border-radius);
}

.RDA_Santidad {
    /* background: #01C6A0;
    color: #183630; */
    background: #DFF5E6;
    color: #0D781E;
    border-radius: var(--padding-left);
    padding-left: var(--padding-right);
    margin-left: var(--margin-left);
    margin-right: var(--margin-right);
    padding-right: var(--border-radius);
}

.RDA_Perdon {
    background: #F6F5BC;
    color: #1F4B38;
    border-radius: var(--padding-left);
    padding-left: var(--padding-right);
    margin-left: var(--margin-left);
    margin-right: var(--margin-right);
    padding-right: var(--border-radius);
}

.RDA_Raiz {
    background: #B5B5FF;
    color: #3B3B50;
border-radius: var(--padding-left);
    padding-left: var(--padding-right);
    margin-left: var(--margin-left);
    margin-right: var(--margin-right);
    padding-right: var(--border-radius);
}